.progress {
  margin: 8px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .bullets {
    display: flex;
    align-items: center;
    justify-content: center;

    .bullet {
      width: 7px;
      height: 7px;
      background-color: #E4E4E4;
      border-radius: 100%;

      &:not(:last-of-type) {
        margin-right: 6px;
      }

      &.past {
        background-color: #b0c7e0;
      }

      &.current {
        width: 9px;
        height: 9px;
        background-color: #3395EA;

        &:not(:first-of-type) {
          margin-left: -1px;
        }

        &:not(:last-of-type) {
          margin-right: 5px;
        }
      }
    }
  }

  .numbers {
    font-size: 14px;
    line-height: 157%;
    color: #9D9D9D;

    strong {
      font-size: 15px;
      line-height: 147%;
      color: #000000;
    }
  }
}
