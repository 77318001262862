.start {
  text-align: center;

  .header-img {
    width: 100%;
    object-fit: cover;
  }

  .title {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0 10px;
    max-width: 400px;
    font-size: 30px;
    line-height: 23px;
  }

  .description {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 400px;
    font-size: 20px;
    line-height: 143%;
  }

  .start-button-container {
    margin-top: 30px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}